<template>
    <div class="gdprSection">
      <h1>{{ $t("form.searchInPersonalData") }}</h1>
      <div>
        <button class="HideExpand" @click="showAllForm(true)" v-if="!collapseAll"> {{ $t("form.expandAll") }} </button>
        <button class="HideExpand" @click="showAllForm(false)" v-else-if="collapseAll"> {{ $t("form.collapse") }} </button>
        <button @click="changeTypeOfSearch()" class="HideExpand">
            <i class="fa fa-repeat" aria-hidden="true"></i>
            {{ $t("changeTypeOfSearch") }}
        </button>
      </div>
    </div>
    <div v-for="metadataClasses in $root.metadataConfigManager.metadataGDPRFields">
        <div class="gdprTitle">
          <h2> {{ $t(String(metadataClasses.label)) }} </h2>
          <button @click="metadataClasses.showItems = !metadataClasses.showItems" v-if="!metadataClasses.showItems"> <i class="fas fa-plus"></i> </button>
          <button @click="metadataClasses.showItems = !metadataClasses.showItems" v-else-if="metadataClasses.showItems"> <i class="fas fa-minus"></i> </button>
        </div>
        <div v-show="metadataClasses.showItems" class="gdprForm">
          <div v-for="gdprFieldItem in metadataClasses.items" :key="gdprFieldItem">
            <div class="inlineflex">
              <p> {{ $t(String(gdprFieldItem.label)) }} </p>
              <button :title=$t(gdprFieldItem.helpPhrase)> <i class="fas fa-circle-question"></i> </button>
            </div>            
            <input v-model="gdprFieldItem.searchPhrase" type="text">
          </div>
        </div>
    </div>
    <button @click="callSearchWithForm()" class="searchbutton"> <i class="fa fa-magnifying-glass" aria-hidden="true"></i> </button>
</template>

<script>

import getFacetQuery from '../../mixins/getFacetQuery'

export default {
    name: "Form",
    data() {
      return {
        isHidden: false,
        collapseAll: false
      }
    },
    mixins: [
      getFacetQuery
    ],
    components: {
    },
    methods: {
      showAllForm(showItems) {
        for (var metadataClasses in this.$root.metadataConfigManager.metadataGDPRFields) {
          this.$root.metadataConfigManager.metadataGDPRFields[metadataClasses].showItems = showItems
        }
        this.collapseAll = !this.collapseAll
      },
      changeTypeOfSearch(){
          this.$root.showContextSearch = !this.$root.showContextSearch
      },
      callSearchWithForm() {
        var queryString = ''
        for(var metadataClasses in this.$root.metadataConfigManager.metadataGDPRFields) {
            for (const [key, metadataField] of Object.entries(this.$root.metadataConfigManager.metadataGDPRFields[metadataClasses].items)) {
              if (typeof metadataField.searchPhrase === 'string' && metadataField.searchPhrase && metadataField.searchPhrase !== '') {
                if (queryString == '') {
                  queryString = metadataField.searchPhrase
                } else {
                  queryString += ' OR ' + metadataField.searchPhrase
                }
              }
            }
          }
          this.$store.commit('setUriParameters', {
            searchPhrase: queryString,
            itemsPerPage: this.$root.recordsPerPage,
            facetQuery: this.getFacetQuery('#All'),
            filterQuery: this.$store.state.uriParams.filterQuery,
            idQuery: this.$store.state.uriParams.idQuuery,
            tabQuery: this.$store.state.uriParams.tabQuery,
            sortQuery: this.$store.state.uriParams.sortQuery
          })
          this.$router.push("/results")
     },
    }
}
</script>

<style lang="scss" scoped src="@/assets/css/SearchForm.scss"/>