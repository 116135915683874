<template>
    <h1 class="loginTitle">
        {{ $t("login.loginlabel") }}
    </h1>
    <div class="loginContent">
        <p class="loginUsername"> {{ $t("login.username") }} </p>
        <input @keyup.enter="authUser" class="loginInput" type="text" v-model="userName">
        <p class="loginPassword"> {{ $t("login.password") }} </p>
        <input @keyup.enter="authUser" class="loginInput" type="password" v-model="password">
        <button @click="authUser" class="loginButton"> {{ $t("login.loginbutton") }} </button>
    </div>
</template>

<script>
import { useCookies } from 'vue3-cookies'
import EnvProvider from 'jvjr-docker-env';

export default {
    name: "Login",
    setup() {
        const { cookies } = useCookies();
        return { cookies };
    },
    data() {
        return {
            userName: '',
            password: '',
            message: ''
        }
    },
    methods: {
        authUser () {
        // read cookie, if no cookie, then login, otherwise cookie!!
        if(this.userName == '' || this.password == '') {
            return null
        }

        let formData = new FormData()
        formData.append('username', this.userName)
        formData.append('password', this.password)
        var current = this

        this.axios.post(EnvProvider.value('API_URL') + this.$root.API_LOGIN, formData).then(result => {
            //console.log(result.status)
            if (result.data['logged_in']) {
                this.message = null;
                var csrf = this.cookies.get('csrfToken')
                if(this.userName === 'testuser@sensedata.sk' && EnvProvider.value('ENV') === 'DEV'){   
                    csrf = this.$root.TOKEN_CSRF
                }
                  // Check if cookies are set (only checks CSRF cookie as the other one is httpOnly)
                  if (!csrf) {
                    console.log('Token is not set.')
                    this.$store.commit('pushMsg', {type: 'error', text: this.$t("loginProblem")})
                } else {
                    // Set logged in user
                    this.cookies.set('userName', this.userName.split('@')[0], "30min", null, EnvProvider.value('HOST_DOMAIN'))
                    this.$store.commit('setLoggedUser', this.userName.split('@')[0])
                    this.$store.commit('pushMsg', {type: 'ok', text: this.$t("loginSucess")})
                    this.$router.push("/selectProduct")
                }                           
            } else {
                this.$store.commit('pushMsg', {type: 'error', text: this.$t("loginFailure")})
            }
        }).catch(
            function (error) {
                // this.$store.commit('pushMsg', {type: 'error', text: this.$t("loginProblem")})
                return Promise.reject(error)
            }
        )
    }
    }
}
</script>

<style lang="scss" scoped src="@/assets/css/views/Login.scss"></style>