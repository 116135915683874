<template>

  <div  v-if='$route.path == "/searchForm"' class="searchSection">
    <h1 class="pageTitle"> {{ $t("contextualSearch") }} </h1>
    <button @click="changeTypeOfSearch()" class="HideExpand">
      <i class="fa fa-repeat" aria-hidden="true"></i>
      {{ $t("changeTypeOfSearch") }}
    </button>
  </div>
  <div class="searchFieldContent">
    <div class="searchBox">
      <vue3-simple-typeahead
        :id="$route.path == '/searchForm' ? 'searchBoxId' : 'searchboxIdResult'"
        :placeholder="$t('searchfield')"
        :items="suggestList"
        :minInputLength="1"
        autocomplete="on"
        @selectItem="selectItem" 
        @onInput="delayTermChanged" 
        @onBlur="onBlur"
      >
      </vue3-simple-typeahead>
      <button @click="gotoResults()" class="goToResultsButton">
        <i class="fa fa-magnifying-glass" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</template>
<script>

import getFacetQuery from '../../mixins/getFacetQuery'
import getBackendMixin from "../../mixins/getBackendUrl"
import eventBus from '../../eventBus'
import EnvProvider from 'jvjr-docker-env';

import axios from 'axios'
import removeDiacritics from '../../utils'

export default {
  name: "SearchField",
  data() {
    return {
      searchPhrase: {
				input: '',
				selection: null,
			},
      suggestList: [],
      timeOutForTermChanged: 0,
      savedExpresions: this.$store.state.savedExpressions,
      top10: this.$store.state.top10Expressions,
      recent: this.$store.state.recentExpressions
    }
  },
  mixins: [
    getFacetQuery, getBackendMixin
  ],
  computed: {
    filteredPosts() {
      return this.posts.filter(post =>
        post.body.toLowerCase().includes(this.search.toLowerCase())
      );
    }
  },
  methods: {
    changeTypeOfSearch(){
        this.$root.showContextSearch = !this.$root.showContextSearch
    },    
    gotoResults() {
      this.$store.commit('setSelectedTab', {})
      this.$store.commit('setSelectedTabs', {
        items: []
      })
      this.$store.commit('setFacetResults', [])
      this.$store.commit('setUriParameters', {
        searchPhrase: this.searchPhrase.input,
        itemsPerPage: this.$root.recordsPerPage,
        facetQuery: this.getFacetQuery('#All'),
        filterQuery: this.$store.state.uriParams.filterQuery,
        idQuery: this.$store.state.uriParams.idQuuery,
        tabQuery: this.$store.state.uriParams.tabQuery,
        sortQuery: this.$store.state.uriParams.sortQuery
      })
      if (this.$route.path === '/searchForm') {
        this.$router.push("/results")
      } else {
        this.$store.dispatch('search', {})
      }
    },
    delayTermChanged (event) {
      clearTimeout(this.timeOutForTermChanged)
      this.timeOutForTermChanged = setTimeout(this.termChanged(event), 200)
    },
    selectItem(item) {
			this.searchPhrase.selection = item;
		},
    onlySupportedChars () {
      var regexp = new RegExp('^[a-zA-Z0-9á-žÁ-Ž()?*„“"~-]')
      var regexp2 = new RegExp('[()?*„“"~!@#%^&_+=.-]{2,}')
      if (String(this.term).match(regexp) && !String(this.term).match(regexp2)) {
        return true
      }
      return false
    },
		termChanged(event) {
      try {
        let suggestForm = new FormData()
        suggestForm.append('q', event.input)
        if (event.input.length !== 0) {
          this.axios.post(EnvProvider.value('API_URL') + this.$root.API_SUGGEST, suggestForm, { 
            headers: { 
              "X-Forwarded-User": this.$store.state.loggedUser
            }
            }).then(result => {
              if (result.status == 200) {
                this.suggestList = result.data.suggestList
              } else {
                  this.$store.commit('pushMsg', {type: 'error', text: 'Odporúčač výsledkov nepracuje správne'})
              }
            })
          this.searchPhrase.selection = null;
          this.searchPhrase.input = event.input;
        }
      } catch (err) {
          console.log('ERROR !', err)
          this.$store.commit('pushMsg', {type: 'error', text: 'Odporúčač výsledkov nepracuje správne'})
      }
		},
		onBlur(event) {
			this.searchPhrase.input = event.input;
		},
    getSavedExpressions () {
      this.activeComponent = 'MyProfileContent';
      axios.get(getBackendMixin.methods.getBackendUrl('identity')).then(response => {
        var username = ''
        if (this.$root.useBackendFacade) {
          username = response.body.username;
        } else {
          username = response.body.identity.username;
        }
        
        /* delim contains also a string for solr as a way to compensate for a bug in the solr way of accessing user specific data
        had to rewrite the ternary operator into an anonymous function*/
        var delim = ''
        if (this.$root.useBackendFacade) {
          delim = "/";
        } else {
          delim = '?fq=user%3A' + encodeURIComponent(username.replace(/:/g, '\\:')) + '&rows=';
        }
        
        axios.get(getBackendMixin.methods.getBackendUrl('getStored') + delim + this.$root.maximumSavedPhrase).then(
          response => {
            if (response.data.response) {
              this.searchData.saved = response.data.response.docs
              this.$store.commit('setsavedExpressions', this.searchData.saved)
            }
            this.loadingVisibility = true
            eventBus.$emit('errorOccured', { msg: 'Môj profil sa nepodarilo načítať:' + response.status })         
          },
                )
            })
        },
      getHistoryExpressions () {
            this.activeComponent = 'lastSearchedTerms';
            axios.get(getBackendMixin.methods.getBackendUrl('identity')).then(response => {
                var username = ''
                if (this.$root.useBackendFacade) {
                    username = response.body.username;
                } else {
                    username = response.body.identity.username;
                }
                
                // delim contains also a string for solr as a way to compensate for a bug in the solr way of accessing user specific data
                var delim = ''
                if (this.$root.useBackendFacade) {
                    delim = "/";
                } else {
                    delim = '?fq=user%3A' + encodeURIComponent(username.replace(/:/g, '\\:'));
                }
                
                axios.get(this.getBackendUrl('history') + delim).then(
                    response => {
                        if (response.data.response) {
                            this.searchData.recent = response.data.response.docs
                            this.$store.commit('setrecentExpressions', this.searchData.recent);
                        }
                        this.loadingVisibility = true
                        eventBus.$emit('errorOccured', { msg: 'Môj profil sa nepodarilo načítať:' + response.status })
                    },
                )
            })
        },
        /*focusTab (tabName) {
          this.activeItem = tabName
          if (tabName === '#MyProfileContent') {
            this.getSavedExpressions()
          } else if (tabName === '#MostSearchedResults') {
            this.getSearchData('#MostSearchedResults')
          } else if (tabName === '#lastSearchedTerms') {
            this.getHistoryExpressions()
          }
        },*/
        async getSearchData (selectedTab) {
            this.loadingVisibility = true
            this.activeComponent = 'MostSearchedResults';
            axios.get(getBackendMixin.methods.getBackendUrl(selectedTab.substring(1))).then(
                response => {
                    this.loadingVisibility = true
                    if (response.data.response) {
                        if (selectedTab === '#MostSearchedResults') {
                            this.searchData.top10 = response.data.response.docs
                            this.$store.commit('settop10Expressions', this.searchData.top10);
                        } else {
                            this.searchData.recent = response.data.response.docs
                            this.$store.commit('setrecentExpressions', this.searchData.recent);
                        }
                    }
                    eventBus.$emit('errorOccured', { msg: 'Môj profil sa nepodarilo načítať:' + response.status })
                },
            )
        }
  },
  async created () {
    eventBus.$on('callRenderInMyProfile', data => {
      this.focusTab(this.$route.hash)
    })

    eventBus.$on('updateMyProfile', data => {
      if (data === '#MyProfileContent') {
        this.getSavedExpressions()
      } else {
        this.getSavedExpressions()
        this.getSearchData('#MostSearchedResults')
        this.getHistoryExpressions()
      }
    })

    if (!this.$route.hash) {
      this.$router.replace({
        query: this.$route.query,
        hash: '#lastSearchedTerms'
      })
    }
    //this.getSavedExpressions()
    // this.getSearchData('#MostSearchedResults')
    // this.getSearchData('#history')
    // this.getHistoryExpressions()
    this.loadingVisibility = false
  },
  mounted () {
    /*if (this.$route.hash) {
      this.focusTab(this.$route.hash)
    }*/
  },
  beforeDestroy () {
    eventBus.$off('callRenderInMyProfile')
  }
}
</script>

<style lang="scss" src="@/assets/css/SearchField.scss"></style>