<template>
  <div class="headerFlex">
    <div class="logoHeader" v-on:click="$router.push({ name: 'SelectProduct'})" >
      <img class="imgLogoHeader" src="../assets/minexLogo.png" alt="">
    </div>
    <div class="profileMenu">

      <!-- help modal window -->
      <a @click="$root.helpModalShow = !$root.helpModalShow"> <b> {{ $t("header.help") }} </b> </a>
      <teleport to="body"> 
        <div class="modalHelp" v-show="$root.helpModalShow">
          <div class="modalViewHelp">
            <helpModal/>
          </div>
        </div>
      </teleport>
      <div class="profileHeader" v-if="$store.state.loggedUser != ''">
        <img class="userIcon" src="../assets/userIcon.svg" @click="$root.profileModalShow = !$root.profileModalShow" alt="">
        <a href="javascript:void(0)"  @click="$root.profileModalShow = !$root.profileModalShow"> {{ $store.state.loggedUser }} </a>
      </div>
      <div class="profileHeader" v-else>
        <img class="userIcon" src="../assets/userIcon.svg" alt="" @click="$router.push({ name: 'Login'})">
        <a href="javascript:void(0)" @click="$router.push({ name: 'Login'})"> {{ $t("login.loginbutton") }}  </a>    
      </div>

      <!-- profile modal window -->
      <teleport to="body">
        <div class="modalHelp" v-show="$root.profileModalShow">
          <div class="modalViewHelp">
            <myProfile/>
          </div>
        </div>
      </teleport>
      <a href="javascript:void(0)" v-if="$store.state.loggedUser != ''" v-on:click="logoutUser()" >{{ $t("header.logout") }}</a>
      <select class="chooseLanguage" v-model="$i18n.locale">
        <option v-for="(lang, i) in locales" :key="`Lang${i}`" :value="lang">   <!--  @click="$root.clearValues()" -->
          <a href="javascript:void(0)"> {{ lang }} </a>
        </option>
      </select>
    </div>
  </div>
  <transition>
    <info v-if="($store.state.msg.length > 0)" />
  </transition>
  <div class="headerLine"></div>
</template>

<script>

import { useCookies } from 'vue3-cookies'
import HelpModal from "../components/HelpModal.vue"
import Info from './Info.vue'
import MyProfile from "../components/MyProfile.vue"
import EnvProvider from 'jvjr-docker-env';

export default {
  name: "Header",
  components: {
    helpModal: HelpModal,
    myProfile: MyProfile,
    Info
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      loginVisible: false,
      loggedUser: '',
      products:["EntSearch","GDPRSuite","Anonyma"],
      locales:["sk","cz","de","en","hu"],
      showMyProfile: false
    }
  },
  mounted() {
      let u = this.cookies.get('userName')
      // need to check token on facade
      // if (this.$route.query.userName) {
      //   u = this.$route.query.userName
      //   this.cookies.set('userToken', this.$route.query.userToken, "30min")
      //   this.cookies.set('userName', this.$route.query.userName, "30min")
      // }
      // console.log(u)
      this.loggedUser = (u != null) ? u : ''
      this.$store.state.loggedUser = (u != null) ? u : ''
  },
  watch: {
        '$store.state.msg.length': {
            handler(val,oldVal){
                if( val > oldVal) {
                    setTimeout(() => { this.$store.commit('popMsg'); }, 7000);
                }
            },
            deep: true
        }
  },
  methods: {
    showLogin() {
      this.loginVisible = true;
    },
    closeLogin() {
      this.loginVisible = false;
    },
    logoutUser(){
      console.log('Logging out user');
      this.axios.get(EnvProvider.value('API_URL') + this.$root.API_LOGOUT
                ).then((result) => {
                    console.log(result.data);
                    console.log('Response data: '+ result.data);
                    if (result.status == 200){
                        this.cookies.remove('userName',null,EnvProvider.value('HOST_DOMAIN'));
                        this.cookies.remove('csrfToken');
                        this.$store.commit('setLoggedUser', '');
                        this.$store.commit('pushMsg', { type: 'ok', text: this.$t("authentication.loggedout") });
                        this.$router.push({ name: 'Login'})
                    } else{
                        this.$store.commit('pushMsg', { type: 'error', text: this.$t("authentication.logouterr") })
                    }
                  }).catch((e) => {
                    console.log(e);
                    // setResponse("This is an error from catch()");
                  });
      console.log('After log out')
    }
  }
}
</script>


<style lang="scss" scoped src="@/assets/css/Header.scss"></style>
<style lang="scss" scoped src="@/assets/css/HelpModal.scss"></style>
