<template>
    <div class="searchRecord">
        <div class="anonymaSendContent">
            <img class="sendAnonyma" src="../../assets/anonymaLogo.svg" :title="$t('results.anonymizeDocument')" @click="setFileToAnonyma(result)">
        </div>
        <div class="nameSection">
            <div @click="displayDocumentContent(result.id)"><i class="fas fa-magnifying-glass fa-lg"></i></div>
            <a class="documentName" :href="modifyLink(result)" target="_blank"> {{ result.filename }}</a>
        </div>
        <p v-html="result.content_txt_sk_highlight ? result.content_txt_sk_highlight : ''"></p>
        <div class="documentMetadata">
            <div v-for="(metadataValue, metadataIndex) in result" >
                <p class="metadataContent" v-if="showMetadata(metadataIndex, result[$root.facetFieldForTab])">  {{ setLabelForMetadata(metadataIndex) }} : {{ setValueForMetadata(metadataValue, metadataIndex) }}</p>
            </div>
        </div>
        <!-- document highlight -->
        <teleport to="body">   
            <div class="modalExport" v-show="showHighlight"> 
                <div class="modalViewExport">
                    <div class="highlightHeaderContent">
                        <h2 class="highlightTitle"> {{ result.filename }} </h2>
                        <div id="closeHighlight" @click="showHighlight = !showHighlight"> <i class="fa fa-xmark fa-lg"></i> </div>
                    </div>
                    <p id="highlightLength" v-html="this.$store.state.HighlightText"></p>
                </div>
            </div>
        </teleport>
    </div>
</template>

<script>
import setGoodStringFormat from '../../mixins/setGoodStringFormat'
import { useCookies } from 'vue3-cookies'
import EnvProvider from 'jvjr-docker-env';

export default {
    name: "Content",
    data () {
        return {
            showHighlight: false,
            DocumentText: ""
        }
    },
    props: {
        result: {
            required: false,
            type: Object
        }
    },
    setup() {
        const { cookies } = useCookies();
        return { cookies };
    },
    mixins: [
        setGoodStringFormat
    ],
    methods: {
        modifyLink (record) {
            // if (record.sourceId === 'fabasoft' && this.$root.useFabasoftRolesExtension) {
            //     var link = record.documentLink
            //     if (record.documentLink.indexOf('?') > -1) {
            //         link += '&'
            //     } else {
            //         link += '?'
            //     }
            //     return link + 'gx=' + this.$root.getCookie('gx') + '&px=' + this.$root.getCookie('px') + '&xx=' + this.$root.getCookie('xx') + '&ux=' + this.$root.getCookie('ux')
            // } 
            if (record.sourceId === 'disk') {
                return EnvProvider.value('FILESYSTEM_URL') + this.$root.FILESYSTEM_DOCUMENT + encodeURIComponent(record.documentId)
            }
            return record.documentLink
        },
        showMetadata (metadataId, tabId) {
            for (var i = 0; i < this.$root.metadataConfigManager.metadataObjects.length; i += 1) {
                var metadataObject = this.$root.metadataConfigManager.metadataObjects[i]
                if (metadataObject.id === tabId) {
                    for (var metadataFields of metadataObject.searchRecordMetadataFields) {
                        if(metadataFields.id === metadataId) {
                            return true
                        }
                    }
                }
            }
            return false
        },
        setFileToAnonyma (record) {
            var documentLink = '&documentLink='
            if (record.sourceId === 'disk') {
                documentLink += EnvProvider.value('FILESYSTEM_URL') + this.$root.FILESYSTEM_DOCUMENT + encodeURIComponent(record.documentId)
            } else {
                documentLink += record.documentLink
            }
            var anchor = document.createElement('a')
            if ( EnvProvider.value('ANONYMA_URL_COOKIES') == 'true') {
                anchor.href = EnvProvider.value('ANONYMA_URL') + '/formview?userToken=' +
                this.cookies.get('userToken') +
                '&userName=' +
                this.cookies.get('userName') +
                documentLink
            } else {
                anchor.href = EnvProvider.value('ANONYMA_URL') + '/formview?' + documentLink.substring(1)
            }
            anchor.target="_blank"
            anchor.click()
        },

        displayDocumentContent (id) {
            this.showHighlight = !this.showHighlight
            this.$store.commit('setHighlightedId', id)
            this.$store.dispatch("fetchDocumentContext", {})
        }
    }
}
</script>
<style lang="scss" scoped src="@/assets/css/ResultContent.scss"></style>