<template>
  <div class="modalHeader">
    <h1 class="pageTitle"> {{ $t("help.title") }} </h1>
    <button class="closeHelpModalWindow" @click="$root.helpModalShow = !$root.helpModalShow"> <i class="fas fa-xmark"></i> </button>
  </div>
  <div class="helpSection">
      <p> {{ $t("help.description") }} </p>
      <div class="border_bottom"></div>
      <div v-for="(exp, expressionIndex) in helpExpressionLength" :key="expressionIndex">
        <h3> {{ $t("help.expressions[" + expressionIndex + "].label") }} </h3>
        <p> {{ $t("help.expressions[" + expressionIndex + "].description") }} </p>
        <p class="bold-font"> {{ $t("help.example") }} </p>
        <div v-for="(exam, exampleIndex) in helpExpressionExampleLength[expressionIndex]" :key="exampleIndex">
          <p> <u> {{ $t("help.expressions[" + expressionIndex + "].example[" + exampleIndex + "]") }} </u> </p>
        </div>
      </div>
    </div>
</template>

<script>

export default {
    name: "TipsForSearch",
    data () {
        return {
          helpExpressionLength: 6,
          helpExpressionExampleLength: [3, 1, 3, 2, 2, 2, 3 ]
        }
    }
}
</script>

<style lang="scss" scoped src="@/assets/css/HelpModal.scss"></style>

