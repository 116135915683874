<template>
    <div class="line"></div>
    <div class="resultFlex">
        <p class="p1"> {{ this.$store.state.top10Expressions }} </p> <p class="p2"> {{ this.$store.state.resultCount }} </p>
    </div>
</template>

<script>
export default {
    name: "MostSearchedResult",
}
</script>

<style lang="scss" scoped src="@/assets/css/MostSearchedResult.scss"/>