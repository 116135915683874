<template>
    <div class="container">
        <div class="mostsearched">
            <h5 class="searchterm"> {{ $t("home.lastFoundExpressions.foundExpression") }} </h5>
            <h5 class="count"> {{ $t("home.top10Expressions.numberOfSearching") }} </h5> 
            <i class="fas fa-arrows-up-down arrow"></i>
        </div>
        <!--
            It originaly looked like this:
            <div v-for="i in 5" :key="i">
                <MostSearchedResult/>
            </div>
        -->
        <div v-for="i in this.searchData.top10" :key="i">
            <MostSearchedResult/>
        </div>
        <v-pagination
            v-model="page"
            :pages="setMaxPage()"
            :range-size="1"
            active-color="#AAAAAA"
            @update:modelValue="setPageSearch()"
        />
    </div> 
</template>

<script>
import MostSearchedResult from "../MyProfile/MostSearchedResult.vue"
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { mounted } from "../../main";

export default {
    name: "MostSearchedResults",
    inheritAttrs: false,
    components: {
        MostSearchedResult, VPagination
    },
    data (){
        return {
            page: 1,
            searchData: {
                top10: this.$store.state.top10Expressions
            },
        }
    },
    methods: {
        setMaxPage () {
            return Math.ceil(this.$store.state.numFound / this.$root.recordsPerPage)
        },
        setPageSearch() {
            this.$store.commit('setPageNumber', this.page)
            this.$store.dispatch('myProfileSearch', {})
            console.log(this.searchData.top10)
        },
    }
}
</script>

<style lang="scss" scoped src="@/assets/css/MostSearchedResults.scss"/>