<template>
    <loadingOverlay :message="message"></loadingOverlay>
    <div class="searchResultsHeader">
        <button class="filterButton" @click="isHidden = !isHidden"> {{ $t("leftSideMenu.filter") }}> </button> 
        <SearchField/>
        <div class="filterAndSaveMenu">
            <button class="bookmarkButton"> <img src="../assets/bookmark.svg"> </button>
            <button class="downloadButton" @click="$root.exportModalShow = !$root.exportModalShow"> <img src="../assets/download.svg"> </button>
            <select id="arrange-by" class="sortFilter" name="options" @change="sortSearch(sortType)" v-model="sortType">
                <option v-for="item in sortOptions" :value="item.value"> {{ $t(String(item.text)) }} </option>
            </select>
        </div>
    </div>
    <div class=searchResultsContent>
        <div class="facetView" v-show="isHidden">
            <leftsidebar/> 
        </div>
        <div class="searchResultView">
            <div>
                <button v-for="(tab, index) in $store.state.allTabs" :index="index" :class="{ active : activeEl == index }" @click="setActiveTabComponent(index, tab.id, tab)" class="tab"> {{ getTabNameById(tab) }}</button>
            </div>
            <div class="searchResults">
                <div class='noResults' v-if='$store.state.searchResults.length === 0'> {{ $t("results.noSearchResults") }}</div>
                <div v-for="index in $store.state.searchResults" :key="index">
                    <resultsContent :result="index"/>
                </div>
                <pagecount class="pageCount"/>
            </div>
        </div>
    </div>
    <teleport to="body">
        <div v-show="$root.exportModalShow" class="modalExport">
            <exportMenu/>
        </div>
    </teleport>
</template>

<script>

import Menu from "@/components/Menu.vue"
import ResultsContent from "@/components/SearchResults/ResultsContent.vue"
import LeftSideBar from "@/components/SearchResults/LeftSidebar.vue"
import PageCount from "@/components/SearchResults/PageCount.vue"
import SearchField from "../components/Search/SearchField.vue"
import ExportMenu from "../components/SearchResults/ExportMenu.vue"
import getFacetQuery from '../mixins/getFacetQuery'
import LoadingOverlay from "../components/LoadingOverlay.vue"

export default {
    name: "Results",
    components: {
        SearchField,
        "resultsContent":ResultsContent,
        "leftsidebar":LeftSideBar,
        "pagecount":PageCount,
        "menu": Menu,
        "exportMenu" :ExportMenu,
        "loadingOverlay": LoadingOverlay
    },
    mixins: [
        getFacetQuery
    ],
    data() {
        return {
            tabs: ['Všetko', 'PDF'],
            resultsTitle: ['Zmluva o podaní výkonu fyzickou osobou', 'Zmluva o kúpe nehnutelnosti fyzickou osobou'],
            activeIndex: 0,
            showModal: false,
            isHidden: true,
            showDoc: false,
            showerr: false,
            showSucess: false,
            activeEl: 0,
            downloadStatus: "fail",
            sortType: '',
            sortOptions: [
                { text: "results.sortByRelevance", value: '' },
                { text: "results.sortByLatest", value: 'creationDate asc' },
                { text: "results.sortByOldest", value: 'creationDate desc' }
            ],
            message: this.$t("LoadingMessages.documentLoading")
        } 
    },
    methods:{
        setActiveTabComponent (index, id, tab) {
            this.activeEl = index;
            this.$store.commit('setSelectedTab', tab)
            this.$store.commit('setUriParameters', {
                searchPhrase: this.$store.state.uriParams.searchPhrase,
                itemsPerPage: this.$root.recordsPerPage,
                facetQuery: this.getFacetQuery('#All'),
                filterQuery: '',
                idQuery: '',
                tabQuery: this.getTabQuery(id),
                sortQuery: this.$store.state.uriParams.sortQuery
            })
            this.createSearchLink()
        },
        sortSearch (sortValue) {
            const sortQuery = sortValue ? ('&sort=' + sortValue) : ''
            this.$store.commit('setUriParameters', {
                searchPhrase: this.$store.state.uriParams.searchPhrase,
                itemsPerPage: this.$root.recordsPerPage,
                facetQuery: this.getFacetQuery('#All'),
                filterQuery: '',
                idQuery: '',
                tabQuery: this.$store.state.uriParams.tabQuery,
                sortQuery: sortQuery
            })
            this.createSearchLink()
        },
        createSearchLink () {
            this.$store.dispatch('search', {})
        },
        getTabNameById (tab) {
            for (var i in this.$root.metadataConfigManager.metadataObjects) {
                if (this.$root.metadataConfigManager.metadataObjects[i].id == tab.id) {
                    return this.$t(String(this.$root.metadataConfigManager.metadataObjects[i].label)) + ' (' + tab.value + ')'
                }
            }
        }
    },
    async created () {
        this.createSearchLink()
    }
}
</script>

<style lang="scss" src="@/assets/css/views/Results.scss"></style>