<template>
    <div class="line"></div>
    <div class="resultFlex">
        <p class="p1"> {{ this.$store.state.recentExpressions }} </p> <p class="p2"> {{ this.$store.state.numFound }} </p>
    </div>
</template>

<script>
export default {
    name: "LastSearchedTerms"
}
</script>

<style lang="scss"  scoped src="@/assets/css/LastSearchedTerm.scss"/> 