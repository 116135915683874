<template>
    <div class="flexProfile">
        <div class="userIcon">
            <h1> {{ $t("myProfile") }} </h1>
            <img class="iconPadding" style="width: 60px; height: 60px;" src="@/assets/userIcon.svg"/>
        </div>
        <button class="exit" @click="$root.profileModalShow = !$root.profileModalShow"> <i class="fa fas fa-x"></i> </button>
    </div>
    <h1 class="historyview"> {{ $t("searchHistory") }} </h1>
    <MyProfileHistory class="historyhelp"/>
</template>
<script>

import getFacetQuery from '../mixins/getFacetQuery'
import getBackendMixin from "../mixins/getBackendUrl"
import History from "../components/Search/MyProfileContent.vue"
import MyProfileHistory from "../components/MyProfileHistory.vue"
import DeleteRecordModal from "../components/MyProfile/DeleteRecordModal.vue"
import EnvProvider from 'jvjr-docker-env';

//import axios from 'axios'
//import removeDiacritics from '../utils'

export default {
    name: "MyProfile",
    data() {
        return {
            suggestList: [],
            savedSearchResult: {},
            term: ""
        }
    },
    components: { History, MyProfileHistory, DeleteRecordModal },
    mixins: [
        getFacetQuery, getBackendMixin
    ],
    methods: {
        async doSearch (e) {
            this.term = String(e);
            // if user want to search, we just publish event to search component which processes the request
            this.$router.push({
                name: 'Search',
                query: {
                    q: this.term,
                    page: 1
                }
            })
            this.$root.searchPhrase = this.term;
            const urlHash = '#' + this.$root.allResultTagId
            const facetQuery = this.getFacetQuery(urlHash)
            // TODO: think of better solution
            this.$store.commit('setPageNumber', 1)
            this.$store.commit('setUriParameters', {
                searchPhrase: this.term,
                searchStart: 0,
                itemsPerPage: this.$root.recordsPerPage,
                facetQuery: facetQuery,
                filterQuery: '',
                idQuery: '',
                tabQuery: '',
                sortQuery: ''
            })
            await this.$store.dispatch('myProfileSearch', {})
            // HOTFIX
            eventBus.$emit('refreshMenu', {})
            eventBus.$emit('refreshFilterBubbles')
        },
        termChanged() {
            if (String(this.term).length >= this.$root.numberOfCharactersForSuggester && this.onlySupportedChars()) {
                // suggestions has to be erased
                var tempTerm = this.term
                var tempSuggestions = []
                var fabasoftUserRolesProperties = ''
                if (this.$root.useFabasoftRolesExtension) { // if in developement mode, ignore roles extensions by default
                    fabasoftUserRolesProperties = '&gx=' + this.$root.getCookie('gx') + '&px=' + this.$root.getCookie('px') + '&xx=' + this.$root.getCookie('xx') + '&ux=' + this.$root.getCookie('ux')
                }
                axios.get(EnvProvider.value('API_URL') + '/suggest?q=' + tempTerm + fabasoftUserRolesProperties).then(response => {
                    var sugestionResult = response.body.suggest
                    var mainSug,freeSug
                    if (sugestionResult.AnalyzingInfixSuggester) {
                        mainSug = suggestionResult.AnalyzingInfixSuggester[tempTerm]
                    }
                    if (sugestionResult.FreeTextSuggester) {
                        freeSug = suggestionResult.FreeTextSuggester[tempTerm]
                    }

                    if (sugestionResult) {
                        var termsSet = new Set()
                        if (mainSug && mainSug.numFound > 0) {
                            for (var index = 0; index < mainSug.numFound; index++) {
                                if (!termsSet.has(removeDiacritics(mainSug.suggestions[index].term.toLowerCase()))) {
                                    termsSet.add(removeDiacritics(mainSug.suggestions[index].term.toLowerCase()))
                                    tempSuggestions.push({ title: mainSug.suggestions[index].term })
                                }
                            }
                        } else if (freeSug && freeSug.numFound > 0) {
                            for (index = 0; index < freeSug.numFound; index++) {
                                if (!termsSet.has(removeDiacritics(freeSug.suggestions[index].term.toLowerCase()))) {
                                    termsSet.add(removeDiacritics(freeSug.suggestions[index].term.toLowerCase()))
                                    tempSuggestions.push({ title: this.term.trim().replace(/\w+[.!?]?$/, '').trim() + ' ' + freeSug.suggestions[index].term })
                            }
                        }
                    }
                }
                this.suggestions = tempSuggestions.reverse()
                }, response => {
                    eventBus.$emit('errorOccured', { msg: 'Chyba textového napovedača:' + response.status })
                })
            }
		},
        created () {
            // when searchbox is created, we have to read URL to correctly show query in input box (it's q parameter)
            if (window.location.pathname === '/searchForm') {
                // get q param from URL
                var paramName = 'q'
                var match = new RegExp('[?&]' + paramName + '=([^&]*)').exec(window.location.search)
                var q = match && decodeURIComponent(match[1].replace(/\+/g, ' '))
                if (q) {
                    this.$root.searchPhrase = q
                    this.term = q
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped src="@/assets/css/MyProfile.scss"></style>