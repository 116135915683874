import { createRouter, createWebHistory } from 'vue-router'
import Login from "@/views/Login.vue"
import Results from "@/views/Results.vue"
import Search from "@/views/Search.vue"
import SelectProduct from "@/views/SelectProduct.vue"
import store from "../store";
import eventBus from '../eventBus'
import axios from 'axios';
import EnvProvider from 'jvjr-docker-env';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta:{requiresAuth:false},
  },
  {
    path: "/selectproduct",
    name: "SelectProduct",
    component: SelectProduct,
    meta:{requiresAuth:true},
  },
  {
    path: "/searchForm",
    name: "Search",
    component: Search,
    meta:{requiresAuth:true},
  },
  {
    path: "/results",
    name: "Results",
    component: Results,
    meta:{requiresAuth:true},
  },
  {
    path: "/:pathMatch(.*)*",
    component: Login,
    meta:{requiresAuth:false},
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to,from,next) => {
  eventBus.$emit("urlChanged",to.path)
  next()
})

async function auth(){
  console.log('Startin auth')
    try {
      const response = await axios.get(EnvProvider.value('API_URL') + '/auth')
      if (response.status === 200){
          console.log('true')
          return true
      } else {
          console.log('false')
          return false
      }
    } catch(err){
      console.log(err)
      return false
    }
}

// Authentication logic on routes
router.beforeEach(async (to, from, next) => {
  if (to.name === 'Login') {
    next() // login route is always  okay (we could use the requires auth flag below). prevent a redirect loop
  } else if (to.meta && to.meta.requiresAuth === false) {
    next() // requires auth is explicitly set to false
  } else if (await auth()) {
    console.log('logged in ?')
    next() // i'm logged in. carry on
  } else {
    console.log('not logged in')
    next({ name: 'Login' }) // always put your redirect as the default case
  }
})

export default router
