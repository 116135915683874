import { mounted } from "../main"
import EnvProvider from 'jvjr-docker-env';

export const getBackendMixin = {
    methods: {
      getBackendUrl (handle) {
        /**
         * returns the required URL according to the requested string. Does support for str:
         * getStored, deleteStored, postStored, history, top, identity.
         * Depening on the instance setting it will either choose solr or facade as the backend.
         */
        // env ci $root, hm?
        // Had to rewrite it to lambda functions, because ternary operator didn´t work as expected
        var returnObj = []
        if (mounted.$data.useBackendFacade) {
          returnObj =  mounted.$data.backendUrlsForFacade;
        } else {
          returnObj =  mounted.$data.backendUrlsForSolr;
        }
        
        var srvUrl = ''
        if (mounted.$data.useBackendFacade) {
          srvUrl  = process.env.PROFILE_URL;
        } else {
          srvUrl  = process.env.PROFILE_URL_SOLR;
        }
        console.log (handle)
        console.log (returnObj[handle])
        if (typeof handle === 'undefined' || returnObj[handle] === undefined) {
          return false
        }
  
        // add this to config useDemoRoute
        if (handle === 'identity' && mounted.$data.useDemoRoute) {
          return process.env.PROFILE_URL_SOLR + returnObj[handle]
        } else if (handle === 'identity' && !mounted.$data.useBackendFacade) {
          return EnvProvider.value('API_URL') + returnObj[handle]
        }
  
        return srvUrl + returnObj[handle]
      }
    }
  }
  
export default getBackendMixin