<template>
  <div class="modalViewExport">
    <h3> {{ $t("authorcards.outputChoice") }} </h3>
    <p class="exportMessage"> {{ $t("authorcards.export") }} {{ $store.state.numFound }} {{ $t("authorcards.records") }} </p>
    <div class="line"></div>
    <div class="exportSection">
      <div>
        <h3>{{ $t("exportMenu.requestType") }}</h3>
        <div class="exportSelectRequest">
          <div>
            <input class="exportRadioButton" type="radio" id="access" name="request" value="access" checked="checked" v-on:click="setTypeOfRequest('access', true)"/>
            <label for="access">{{ $t("exportMenu.dataAccess") }}</label>
          </div>
          <div>
            <input class="exportRadioButton" type="radio" id="correction" name="request" value="correction" v-on:click="setTypeOfRequest('correction', true)"/>
            <label for="correction">{{ $t("exportMenu.dataRepair") }}</label>
          </div>
          <div>
            <input class="exportRadioButton" type="radio" id="reset" name="request" value="reset" v-on:click="setTypeOfRequest('reset', true)"/>
            <label for="reset">{{ $t("exportMenu.dataErase") }}</label>
          </div>
          <div>
            <input class="exportRadioButton" type="radio" id="restriction" name="request" value="restriction" v-on:click="setTypeOfRequest('restriction', true)"/>
            <label for="restriction">{{ $t("exportMenu.processingRestriction") }}</label>
          </div>
          <div>
            <input class="exportRadioButton" type="radio" id="export" name="request" value="export" v-on:click="setTypeOfRequest('export', false)" />
            <label for="export">{{ $t("exportMenu.dataExport") }}</label>
          </div>
        </div>
      </div>
      <div>
        <h3> {{ $t("results.format") }} </h3>
        <div class="exportSelectFormats">
          <input v-show="gdprExports" class="exportMethodbutton" type="button" value="DOC" @click="activeExportElement = 'doc'" :class="{ active : activeExportElement == 'doc' }">
          <input v-show="gdprExports" class="exportMethodbutton" type="button" value="PDF"  @click="activeExportElement = 'pdf'" :class="{ active : activeExportElement == 'pdf' }">
          <input v-show="!gdprExports" class="exportMethodbutton" type="button" value="JSON" @click="activeExportElement = 'zip'"  :class="{ active : activeExportElement == 'zip' }">
          <input v-show="!gdprExports" class="exportMethodbutton" type="button" value="CSV" @click="activeExportElement = 'csv'"  :class="{ active : activeExportElement == 'csv' }">
          <input v-show="!gdprExports" class="exportMethodbutton" type="button" value="Excel"  @click="activeExportElement = 'xlsx'" :class="{ active : activeExportElement == 'xlsx' }">
        </div>
      </div>
    </div>
    <div class="exportMenu">
      <button class="backbutton" @click="$root.exportModalShow = !$root.exportModalShow"> {{ $t("results.backbutton") }} </button>   
      <button class="exportbutton" @click="callExportMethod"> {{ $t("leftSideMenu.exportbutton") }} </button>
    </div>
  </div>  
</template>

<script>
import EnvProvider from 'jvjr-docker-env';

export default {
    name: "ExportMenu",
    data(){
        return{
          activeExportElement: 'docExport',
          activeTypeOfRequest: 'access',
          gdprExports: true,
        }
    },
    methods: {
      setTypeOfRequest (typeOfRequest, gdprExports) {
        this.activeTypeOfRequest = typeOfRequest
        this.gdprExports = gdprExports
      },
      callExportMethod () {
        if (this.activeExportElement === 'csv' || this.activeExportElement === 'xlsx') {
            this.$store.commit('setExportParameters', {
              fl: this.setExportMetadata(),
              exportType: this.setExportType(),
              exportEnd: this.activeExportElement
            })
          this.$store.dispatch('export', {})
        } else {
          try {
            var exportURI = EnvProvider.value('REPORT_URL') + this.$root.API_REPORT + '/' + this.activeExportElement + '?q=' + this.$store.state.uriParams.searchPhrase + '&exportType=' + this.activeTypeOfRequest
            var fileLink = document.createElement('a');
            fileLink.href = exportURI;
            document.body.appendChild(fileLink);
            fileLink.setAttribute('target', '_blank');
            fileLink.click();
          }
          catch(err) {
            this.$store.commit('pushMsg', {type: 'error', text: 'V exportovaní reportu sa vyskytla chyba'})
          }
        }
      },
      setExportMetadata () {
        var fl = '&fl=Meno:name,Id dokumentu:documentId,Zdroj:sourceId,Autor:createdBy,Dátum vytvorenia:creationDate'
        return fl
      },
      setExportType () {
        if (this.activeExportElement === 'csv') {
          return '&wt=csv'
        } else if (this.activeExportElement === 'xlsx') {
          return '&wt=xlsx'
        }
      }
    }
}
</script>
