<template>
  <div class="allMenu">
    <div v-if="$route.name == 'SelectProduct' || $route.name == 'Search' || $route.name == 'Results'" class="menuItem" @click="changeRouting('SelectProduct')">
      <i class="fa-solid fa-house"/>      
      {{ $t("other.home") }}
    </div>
    <div v-if="$route.name == 'Search' || $route.name == 'Results'"  @click="changeRouting('Search')" class="menuItem">
      <div class="menuItemSpace">
        >
      </div>
      {{ $t("search") }}
    </div>
    <div v-if="$route.name == 'Results'"  @click="changeRouting('Results')" class="menuItem">
      <div class="menuItemSpace">
        >
      </div>
      {{ $t("other.results") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  methods:{
    changeRouting (viewName) {
      console.log(viewName)
      this.$router.push({
        name: viewName,
      })
    }
  }
}
</script>


<style lang="scss" src="@/assets/css/Menu.scss"/>
