const getForm = (uriParams, pageNumber, formMethod) => {
    // let fabasoftUserRolesProperties = ''
    // if (rootConfig.useFabasoftRolesExtension) { // if in development mode, ignore roles extensions by default
    //   fabasoftUserRolesProperties = '&gx=' +
    //   rootConfig.getCookie('gx') +
    //   '&px=' +
    //   rootConfig.getCookie('px') +
    //   '&xx=' +
    //   rootConfig.getCookie('xx') +
    //   '&ux=' +
    //   rootConfig.getCookie('ux')
    // }

    let formSearchData = new FormData()
    formSearchData.append('q', uriParams.searchPhrase)
    formSearchData.append('rows', uriParams.itemsPerPage)
    formSearchData.append('start', ((pageNumber - 1) * uriParams.itemsPerPage))
    formSearchData.append('facetQuery', uriParams.facetQuery)
    formSearchData.append('tabQuery', uriParams.tabQuery)
    formSearchData.append('sortQuery', uriParams.sortQuery)

    // const searchUri = process.env.API_URL +
    //   '/search?q=' +
    //   uriParams.searchPhrase +
    //   '&start=' +
    //   uriParams.searchStart +
    //   '&rows=' +
    //   uriParams.itemsPerPage +
    //   (uriParams.facetQuery ? uriParams.facetQuery : '') +
    //   (uriParams.filterQuery ? uriParams.filterQuery : '') +
    //   (uriParams.idQuery ? uriParams.idQuery : '') +
    //   (uriParams.tabQuery ? uriParams.tabQuery : '') +
    //   (uriParams.sortQuery ? uriParams.sortQuery : '') +
    //   process.env.AUTH_OFF +
    //   fabasoftUserRolesProperties

    // const exportUri = process.env.API_URL +
    //   '/search?q=' +
    //   uriParams.searchPhrase +
    //   '&start=0&rows=' +
    //   rootConfig.recordsPerExport +
    //   uriParams.itemsPerPage +
    //   (uriParams.facetQuery ? uriParams.facetQuery : '') +
    //   (uriParams.filterQuery ? uriParams.filterQuery : '') +
    //   (uriParams.idQuery ? uriParams.idQuery : '') +
    //   (uriParams.tabQuery ? uriParams.tabQuery : '') +
    //   (uriParams.sortQuery ? uriParams.sortQuery : '') +
    //   process.env.AUTH_OFF +
    //   fabasoftUserRolesProperties
    return formSearchData
  }
  
  export default getForm
  