<template>
<div class="footer">
  <div class="footerFlex">
    <div class="logoSection">
      <img class="imgLogoFooter" src="../assets/minexLogo.png" alt="">
    </div>
    <div class="footerContent">
      <div>
        {{ $t("footer.upperLabel") }}
      </div>      
      <div>
        {{ $t("footer.bottomLabel") }}
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Footer"
}
</script>


<style lang="scss" scoped src="@/assets/css/Footer.scss"></style>
