<template>
    <div class="facetContent">
        <div class="facetCategories">
            <p class="filterCategoriesTitle"> {{ $t("categories") }} </p>
            <button v-if="!categories" @click="categories = !categories"> <i class="fa fa-plus iconplacement"></i> </button>
            <button v-else-if="categories" @click="categories = !categories"> <i class="fa fa-minus iconplacement"></i> </button>
        </div>
        <div class="oneFacetAttribute" v-show="categories">
            <input class="facetCheckbox" type="checkbox" >
            <label> {{ $t("leftSideMenu.myDocs") }} </label>
        </div>
        <div class="oneFacetAttribute" v-show="categories">
            <input class="facetCheckbox" type="checkbox" value="Mnou Upravené" name="Mnou upravené">
            <label> {{ $t("leftSideMenu.mylastEdittedDocs") }} </label>
        </div>
        <div class="facetCategories">
            <p class="filterCategoriesTitle"> {{ $t("metadataFieldLabels.lastModifiedBy") }} </p>
            <button v-if="!lastUpdated" @click="lastUpdated = !lastUpdated"> <i class="fa fa-plus iconplacement"></i> </button>
            <button v-else-if="lastUpdated" @click="lastUpdated = !lastUpdated"> <i class="fa fa-minus iconplacement"></i> </button>
        </div>
        <div class="facetSearchBox" v-show="lastUpdated">
            <vue3-simple-typeahead
                class="inputfield"
                :placeholder="$t('searchfield')"
                :items="suggestList"
                :minInputLength="1"
                autocomplete="on"
                @selectItem="selectItem" 
                @onInput="delayTermChanged" 
                @onBlur="onBlur"
            />
            <!-- <button class="searchbutton"> <i class="fa fa-magnifying-glass" aria-hidden="true"></i> </button> -->
        </div>
        <div class="oneFacetAttribute" v-for="i in 5" v-show="lastUpdated">
            <input class="facetCheckbox" type="checkbox" >
            <label>kbr(0)</label>
        </div>
        <div class="facetCategories">
            <p class="filterCategoriesTitle"> {{ $t("metadataFieldLabels.creationDate") }} </p>
            <button v-if="!dateOfCreation" @click="dateOfCreation = !dateOfCreation"> <i class="fa fa-plus iconplacement"></i> </button>            
            <button v-else-if="dateOfCreation" @click="dateOfCreation = !dateOfCreation"> <i class="fa fa-minus iconplacement"></i> </button>
        </div>
        <input class="dateRange" type="range" min="2005" max="2021" value="2020" step="2" v-show="dateOfCreation">
        <p class="nowrap dateRange" v-show="dateOfCreation"> {{ monthsLabels[0] }} 2005  - {{ monthsLabels[1] }} 2021</p>
        <div class="facetCategories">
            <p class="filterCategoriesTitle"> {{ $t("metadataFieldLabels.lastModificationDate") }} </p>
            <button v-if="!time" @click="time = !time"> <i class="fa fa-plus iconplacement"></i> </button>
            <button v-else-if="time" @click="time = !time"> <i class="fa fa-minus iconplacement"></i> </button>
        </div>
        <input class="dateRange" type="range" min="2005" max="2021" value="2020" step="2" v-show="time">
        <p class="nowrap dateRange" v-show="time"> {{ monthsLabels[8] }} 2005 - {{ monthsLabels[10] }} 2021</p>
        <div class="facetControlButton">
            <button class="resetFacetButton"> {{ $t("leftSideMenu.filterReset") }} </button>
            <button class="facetSearch"> {{ $t("leftSideMenu.searchbutton") }} </button>
        </div>
    </div>
</template>

<script>
import EnvProvider from 'jvjr-docker-env';

export default {
    name: "LeftSideBar",
    data() {
        return {
            monthsLabels: ['január', 'február', 'marec', 'apríl', 'máj', 'jún', 'júl', 'august', 'september', 'október', 'november', 'december'],
            categories: true,
            lastUpdated: true,
            dateOfCreation: true,
            time: true,
            suggestList: [],
            searchPhrase: {
				input: '',
				selection: null,
			},
        }
    },
    methods: {
        delayTermChanged (event) {
            clearTimeout(this.timeOutForTermChanged)
            this.timeOutForTermChanged = setTimeout(this.termChanged(event), 200)
        },
        onBlur(event) {
			this.searchPhrase.input = event.input;
		},
        termChanged(event) {
            try {
                let suggestForm = new FormData()
                suggestForm.append('q', event.input)
                if (event.input.length !== 0) {
                this.axios.post(EnvProvider.value('API_URL') + this.$root.API_SUGGEST, suggestForm, { 
                    headers: { 
                        "X-Forwarded-User": this.$store.state.loggedUser
                    }
                    }).then(result => {
                    if (result.status == 200) {
                        this.suggestList = result.data.suggestList
                    } else {
                        this.$store.commit('pushMsg', {type: 'error', text: 'Odporúčač výsledkov nepracuje správne'})
                    }
                })
                this.searchPhrase.selection = null;
                this.searchPhrase.input = event.input;
                }
            } catch (err) {
                console.log('ERROR !', err)
                this.$store.commit('pushMsg', {type: 'error', text: 'Odporúčač výsledkov nepracuje správne'})
            }
        },
        selectItem(item) {
			this.searchPhrase.selection = item;
		},
    }
}
</script>

<style lang="scss"  scoped src="@/assets/css/LeftSideBar.scss"/>