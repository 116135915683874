<template v-if="$store.state.msg.length > 0">
<!-- <div>{{$store.state.msg.length > 0}}</div> -->
    <div id="info-outer" :class="'info-'+$store.state.msg[0].type">
        <div id="info-textbox"> {{ $store.state.msg[0].text }} </div>
        <div id="info-close" @click="$store.commit('popMsg')" > x </div>
        <!-- {{$store.state.msg[0]}} -->
    </div>
</template>

<script>
export default {
    name: 'Info',
    // props: ["csstype", "message"],
    setup() {
    },
    mounted(){
        // console.log(this.$store.state)
    },
    data() {
        return {
            // type: "info-ok"
        }
    },
    methods: {
        
    },
};
</script>

<style lang="scss" src="@/assets/css/Info.scss"></style>
