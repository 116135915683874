<template>
    <div class="termSearchView">
        <p> {{ $t("home.lastFoundExpressions.buttonDescription") }} </p>
        <div class="savedexpression" v-for="el in this.searchData.recent" :key="el">
            <div class="termdeleteview">
                {{ el }} <button @click="testModal()" class="termdeletebutton"> {{ $t("rightClickMenu.delete") }} </button>
            </div>
        </div>
        <v-pagination
            v-model="page"
            :pages="setMaxPage()"
            :range-size="1"
            active-color="#AAAAAA"
            @update:modelValue="setPageSearch()"
        />
    </div>
</template>

<script>
import LastSearchedTerm from "../MyProfile/LastSearchedTerm.vue"
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import { mounted } from "../../main";

export default {
    name: "LastSearchedTerms",
    components: {
        LastSearchedTerm, VPagination
    },    
    data (){
        return {
            page: 1,
            searchData: {
                recent: this.$store.state.recentExpressions
            },
        }
    },
    methods: {
        setMaxPage () {
            return Math.ceil(this.$store.state.numFound / this.$root.recordsPerPage)
        },
        setPageSearch() {
            this.$store.commit('setPageNumber', this.page)
            this.$store.dispatch('myProfileSearch', {})
            console.log(this.searchData.recent)
        },
    }
}
</script>

<style lang="scss"  scoped src="@/assets/css/LastSearchedTerms.scss"/>