<template>
    <div class="chooseProduct">
        <div id="chooseSearch" class="chooseType" @click="prelinkToSearch()">
            <div class="chooseIcons chooseSearch">
                <i class="fas fa-magnifying-glass fa-6x"/>
            </div>
            <h3 class="chooseText">{{ $t("selectProduct.searchDocument") }}</h3>
        </div>
        <div id="chooseAnonyma" class="chooseType" @click="prelinkToAnonyma()">
            <i class="fas fa-cloud-arrow-up fa-8x"/>
            <h3 class="chooseTextAnonyma">{{ $t("selectProduct.uploadDocument") }}</h3>
        </div>
    </div>
</template>

<script>

import { useCookies } from 'vue3-cookies'
import EnvProvider from 'jvjr-docker-env';

export default {
    name: "SelectProduct",
    setup() {
        const { cookies } = useCookies();
        return { cookies };
    },
    methods: {
        prelinkToSearch() {
            this.$router.push("/searchForm");
        },
        prelinkToAnonyma() {
            if ( EnvProvider.value('ANONYMA_URL_COOKIES') == 'true') {
                window.location.href = EnvProvider.value('ANONYMA_URL') + '?userToken=' +
                this.cookies.get('userToken') +
                '&userName=' +
                this.cookies.get('userName')
            } else {
                this.$store.commit('clearMsg')
                window.location.href = EnvProvider.value('ANONYMA_URL')
            }
        }
    }
}
</script>

<style lang="scss" scoped src="@/assets/css/views/SelectProduct.scss"></style>